import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/DefaultLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`„Jede Generation junger Gläubiger erhält eine Gelegenheit, für die Geschicke der Menschheit einen Beitrag zu leisten, die einzigartig für ihre Lebenspanne ist. Für die gegenwärtige Generation ist der Augenblick gekommen, tief nachzudenken, sich zu verpflichten, sich zu stählen für ein Leben des Dienstes, aus dem Segen im Überfluss strömen wird.”`}</p>
      <cite>—Das Universale Haus der Gerechtigkeit</cite>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bd79d8e7d5047d6e26f4745490283460/10a1e/juniorjugendprogramm.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAHaTsaXktT/AP/EABoQAQADAAMAAAAAAAAAAAAAAAIBAwQREiL/2gAIAQEAAQUCWj1XosQOoQeeyCkhKsz/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPwHEf//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AVP/xAAcEAEAAgIDAQAAAAAAAAAAAAABABESMQITIWH/2gAIAQEABj8Cyx5gQd1tqGdj9ieHkLbF1K6if//EABwQAAICAgMAAAAAAAAAAAAAAAERADEhcWGBof/aAAgBAQABPyEYE67z1GYLMFoONIuAhIRZOEsmWp7EofZc/9oADAMBAAIAAwAAABDz7//EABgRAAIDAAAAAAAAAAAAAAAAAAABEVFh/9oACAEDAQE/EIR2PZ//xAAYEQEBAAMAAAAAAAAAAAAAAAABABExUf/aAAgBAgEBPxAV3Ycv/8QAHhAAAgICAgMAAAAAAAAAAAAAAREAITFBUWFxkfD/2gAIAQEAAT8QBWsjBHeW8ZgrPQAAvpUgBuFIW7Q5qHbBbsr1GqPPt8ES2IFnLHJn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "juniorjugendprogramm",
            "title": "juniorjugendprogramm",
            "src": "/static/bd79d8e7d5047d6e26f4745490283460/e5166/juniorjugendprogramm.jpg",
            "srcSet": ["/static/bd79d8e7d5047d6e26f4745490283460/f93b5/juniorjugendprogramm.jpg 300w", "/static/bd79d8e7d5047d6e26f4745490283460/b4294/juniorjugendprogramm.jpg 600w", "/static/bd79d8e7d5047d6e26f4745490283460/e5166/juniorjugendprogramm.jpg 1200w", "/static/bd79d8e7d5047d6e26f4745490283460/d9c39/juniorjugendprogramm.jpg 1800w", "/static/bd79d8e7d5047d6e26f4745490283460/df51d/juniorjugendprogramm.jpg 2400w", "/static/bd79d8e7d5047d6e26f4745490283460/10a1e/juniorjugendprogramm.jpg 3504w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Als Juniorjugendliche werden Jugendliche in der Altersgruppe von 12 bis 15 Jahren bezeichnet. Diese Zeit im Leben des Menschen ist besonders, weil es zu vielen körperlichen, emotionalen und intellektuellen Veränderungen kommt. Die Baha’i-Gemeinde bietet Gruppen für diese Altersgruppe an, die allen Juniorjugendlichen offen stehen.`}</p>
    <p>{`​Das Ziel der `}<a parentName="p" {...{
        "href": "https://www.bahai.de/gemeinsames-handeln/jugend/arbeit-mit-juniorjugendlichen/"
      }}>{`Juniorjugendgruppen`}</a>{` ist es, die Bedürfnisse der Jugendlichen zu begegnen, sich in der Gesellschaft einzubringen und ihren Gedanken Ausdruck zu verleihen. Gemeinsam überlegen sie, wie sie zum gesellschaftlichen Wandel beitragen können. So führen sie beispielsweise in ihrer Umgebung kleine soziale Projekte durch. Sie lesen Geschichten über andere Juniorjugendliche und beschäftigen sich mit Themen wie Freundschaft, Hoffnung und ihren eigenen Talenten und Fähigkeiten.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      